import '../../styles/portfolio.css';
import { Article } from './Article_modele'
import Chapter1_img from '../../assets/Blog/background_chapter1.jpg'
import img_chapter1 from '../../assets/Blog/img_article_chapter1.jpg'
import Chapter2_img from '../../assets/Blog/background_chapter2.jpg'
import img_chapter2 from '../../assets/Blog/img_article_chapter1.jpg'
import Chapter3_img from '../../assets/Blog/background_chapter3.jpg'


export const Chapter1_article = () => {
    const story_intro = (<>
        <p>📝  Il faisait froid ce soir-là, l’air était chargé d’humidité et de fraîcheur. Une douce odeur boisée et épicée flottait autour du marché. Un mélange d’épices, de vin chaud et de bois brûlé réchauffait les cœurs </p>

        <p>Pierre resserra son écharpe autour de son cou. Il venait de terminer sa journée dans la cave familiale qu’il dirigeait avec son père en Valais. Les affaires étaient stables, mais loin d’être florissantes.</p>

        <p>Sur le chemin qui menait au marché, une question lui trottait en tête : pourquoi les concurrents attirent-ils autant de touristes alors que nous peinons à nous faire connaître ?
        <br/>En effet, la cave du voisin affichait souvent complet. Les visites guidées étaient réservées plusieurs semaines à l’avance. À l’inverse, Pierre devait se battre pour remplir ses créneaux.</p>
        <p>La réponse se trouvait dans un mot qu’il ne maîtrisait pas : <strong>la digitalisation</strong></p>
    </>
    )

    const textOne = (<>
        <p>Dans un monde de plus en plus connecté, la digitalisation est devenue une nécessité pour les entreprises, quelle que soit leur taille ou leur secteur. Elle vise à rendre les entreprises plus agiles et performantes. L’un des piliers essentiels de cette transformation numérique est la création d’un site web adapté, réactif et optimisé.
        <br/>C'est un outil indispensable pour gagner en visibilité. </p>
        <p>La digitalisation a pour objectif de rendre une entreprise plus agile et plus performante sur son secteur. <br/><strong>L’une des approches et la proposition d’un site web adapté et réactif.</strong>
   </p>
   </>
    )

    const textTwo = (<>
    <p>Le Valais grâce à  ses paysages et son attrait touristique attire des milliers de visiteurs chaque année et regorge d’entrepreneurs locaux. Pourtant, un manque de visibilité sur internet prive de nombreuses entreprises de clients potentiels.</p>
    <p>Selon une étude Google, près de 81% des consommateurs recherchent un produits ou service en ligne avec de prendre une décision d’achat. Ces habitudes de consommations sont encore plus flagrantes chez les jeunes.</p>
    </>
    )

    const textThree = (<>
    <p> En Valais, de nombreuses entreprises reposent sur des savoir-faire artisanaux ou familiaux. Un site bien conçu valorise l’authenticité et rassure les clients potentiels. </p>
    <p>Des études menés par Stanford Web Credibility Research, indique que 75% des utilisateurs jugent la crédibilité d’une entreprise via la qualité de son site web. </p>
    </>
    )

    const conclusion_text = (<>
    <p> Grâce au référencement naturel (SEO), les entreprises apparaîssent en tête des résultats google.Facilitant ainsi la capacité et trouver de nouveaux clients sans avoir besoin d’intermédiaire.</p>
    <p><strong>Pour conclure</strong>, avoir un site web en Valais est encore plus essentiel car,</p>
    <p>📌 <strong>C’est une destination prisée</strong> qui chaque année amène de nombreux visiteurs. </p>
    <p>📌 <strong>C’est une régions montagneuses</strong> où les déplacements peuvent être contraigants. Un site permet aux clients de trouver facilement les informations sans avoir à se déplacer. </p>
     <p>📌 <strong>Un site internet valorise les produits locaux.</strong> Qu’il s’agisse de vins, de fromages ou d’atisanat, une bonne présence en ligne fait découvrir les richesses du Valais.</p>
      </>
      )
      
    return (
        <div>
            <Article
                img_article={Chapter1_img}
                legend_img_article="Le manque de visibilité des entreprises"
                date="27.11.2024"
                title_story="Chapitre 1 : Le manque de visibilité"
                story_intro={story_intro}
                title_subject="Pourquoi les PME ont besoin d’un site web en Valais ? "
                textOne={textOne}
                question1="Outil indispendable pour gagner en visibilité"
                texttwo={textTwo}
                img_parttwo={img_chapter1}
                question3="Un site bien structuré et adapté aux smartphones donne confiance"
                textthree={textThree}
                conclusion="Une opportunité pour se démarquer de la concurrence"
                conclusion_text={conclusion_text}
                teaser=" 📝 Dans le prochain chapitre, découvrez quel à été la première démarche de Pierre."
                link="https://credibility.stanford.edu/"
                etude="Stanford Web Credibility Research"
            />
        </div>
    )
}
export const Chapter2_article = () => {
    const story_intro = (<>
        <p>📝  Pierre arrive au marché. Il avait rendez-vous avec Frank son ami d’enfance. <br/> Il l’attendait à un stand de vin chaud. Les deux amis se saluèrent chaleureusement et commencèrent à discuter : </p>

        <p> - Alors comment la savonnerie ? demanda Pierre, curieux.<br/>
         - Très bien ! On à pas mal de commandes en ligne en ce moment”, répondit Frank <br/>
        - Vous avez un site internet, vous ?<br/>
        - Oui, c’est moi qui l’ai fait. Rien de compliqué, avec un outil gratuit en ligne.</p>
        <p>Frank sortit son téléphone et tapa l’adresse en arborant un sourire fier, mais quelques secondes de silence gêné s’installèrent entre les deux compagnons.
        <p/> - Ah, attends … c’est un peu lent …, dit Frank</p>
        <p>La page s’ouvrit enfin. Malgré tout le site n’était pas très engageant. <br/> Un design dépassé, des images pixelisée et une navigation complexe.</p>
        <p>Frank avoua que son site n’était pas très adapté, qu’il avait peu de visite et qu’il galérait à le mettre à jour</p>
    </>
    )

    const textOne = (<>
        <p>Frank illustre bien certains désavantages des outils gratuits pour la création de sites internet :  </p>
        <p><strong> Performances limités : </strong> la lenteur peut décourager les visiteurs, surtout aujourd’hui où les utilisateurs s’attendent à ce que les pages s’affichent en quelques secondes. Les outils gratuits n’optimisent pas toujours les performances techniques, comme le poids des images, la structure du code. </p>
        <p> <strong> Designs restrictifs : </strong> es outils gratuits proposent des modèles prédéfinis, souvent peu personnalisables.  Ce qui peut conduire à un design daté et peu attractif.  
        <br/>  Cette uniformité limite également la capacité à se démarquer face à la concurrence. </p>
        <p>  <strong> Fonctionnalités limités : </strong>Ces outils ne permettent pas toujours d’ajouter des fonctionnalités essentielles telles que un système de paiement, un blog, un référencement optimisé par exemple.   </p>

   </>
    )
    const conclusion_text = (<>
    <p> Créer un site web avec un outil gratuit peux sembler attractif pour débuter, mais cela peut engendrer plusieurs complications à long terme :  </p>
    <p>📌 Des fonctionnalités limitées et difficilement personnalisables. </p>
    <p>📌 Des problèmes de maintenance et des performances insuffisantes (lenteur). </p>
     <p>📌 Une apparence peu professionnelle, qui peut nuire à la crédibilité de l’entreprise.</p>
      </>
      )
      
    return (
        <div>
            <Article
                img_article={Chapter2_img}
                legend_img_article="La première tentative"
                date="22.01.2025"
                title_story="Chapitre 2 : La première tentative"
                story_intro={story_intro}
                title_subject="Les limites des outils gratuits de création de sites web "
                textOne={textOne}
                
                conclusion="Pour conclure"
                conclusion_text={conclusion_text}
                teaser=" 📝 Dans le prochain chapitre, découvrez comment Pierre décide de prendre les choses en main."
                link="https://credibility.stanford.edu/"
                etude="Stanford Web Credibility Research"
            />
        </div>
    )
}
    export const Chapter3_article = () => {
        const story_intro = (<>
            <p>📝  Le lendemain matin, Pierre repensa à sa conversation avec Frank.
             <br/> ”Créer un site soi-même… le résultat risque de manquer de performance et de professionnalisme.”  </p>
    
            <p> Il fronça les sourcils, hésitant. “Est-ce que ça vaut vraiment le coup de le faire seul ?”</p>
            <p>C’est alors qu’un souvenir lui revint : Anna, son amie d’enfance, passionnée par l’informatique depuis toujours, rêvait de créer des outils afin d’aider les entreprises à se démarquer. 
            </p>
            <p>”Peut-être qu’Anna pourrait m’éclairer sur l’avantage de faire appel à un professionnel”</p>
        </>
        )
    
        const textOne = (<>
            <p>Quand on parle de professionnel, on parle d’une personne formée, avec une expertise solide dans la conception de sites internet. <br/>  Son rôle ne se limite pas à “faire le site”, mais à créer une solution sur-mesure, adaptée aux besoins spécifiques de chaque client.  </p>
            <p><strong> Une expertise technique : </strong> Un professionnel mettre en place un site sécurisé, performant et accessible sur tous les supports (ordinateurs, tablettes, smartphones). <p> Il est capable d’anticiper les problèmes potentiels et de les corriger avant qu’ils ne deviennent gênants pour votre activité. </p> </p>
            <p> <strong> Un design sur-mesure : </strong> Contrairement à des solutions “clé en main”, un professionnel peut concevoir un design adapté aux besoins de chaque client.  
            <br/>  Ce design est moins restrictif et permet de refléter parfaitement l’identité de l’entreprise. </p>
            <p>  <strong> Un gain de temps précieux : </strong>Faire appel à un professionnel peut sembler plus coûteux au départ, mais c’est un véritable gain de temps.  <br/>  La création d’un site demande beaucoup de travail : design, contenu, performances, SEO, … En confiant cette tâche à un expert, vous pouvez vous concentrer sur ce que vous faites de mieux : gérer et développer votre entreprise.  </p>
    
       </>
        )
        const conclusion_text = (<>
        <p> Un site web est bien plus qu’un simple outil. C’est la vitrine de votre entreprise. En choisissant un professionnel, vous investissez dans une solution durable, adaptée à vos besoins et prête à évoluer avec votre activité. </p>
          </>
          )
          
        return (
            <div>
                <Article
                    img_article={Chapter3_img}
                    legend_img_article="L'appel à un professionnel"
                    date="05.03.2025"
                    title_story="Chapitre 3 : L'appel à un professionnel"
                    story_intro={story_intro}
                    title_subject="Un professionnel pour la conception d'un site web "
                    textOne={textOne}
                    
                    conclusion="Pour conclure"
                    conclusion_text={conclusion_text}
                    teaser=" 📝 Dans le prochain chapitre, découvrez la rencontre entre Anna et Pierre et les étapes de la conception."
                    link="https://www.thinkwithgoogle.com/_qs/documents/3266/a061d_Mobile-page-speed-new-industry-benchmarks-FR.pdf?"
                    etude="Think with Google"
                />
            </div>
        )
    }