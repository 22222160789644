import border from "../assets/border.png"
import chapter1 from "../assets/Blog/Chapter1.png"
import chapter2 from "../assets/Blog/Chapter2.jpg"
import chapter3 from "../assets/Blog/Chapter3.jpg"
import "../styles/blog.css"
import { PostStructure } from "./ui/Post"
export const BlogContainer = () => {
    const text_chapter1 = 
    <p>Il faisait froid ce soir-là, l’air était chargé d’humidité et frais. Une douce odeur boisé et épicé flottait autour du marché. Un mélange d’épice, de vin chaud et de bois brûlé réchaufaient les coeurs.
<p/>
    Pierre resserra son écharpe autour du corps. Il venait de terminer sa journée dans la cave familiale qu’il dirigeait avec son père en Valais. Les affaires étaient stables, mais loin d’être florissantes.
    Sur le chemin qui menait au marché ...</p>
    
    const text_chapter2 = 
    <p> Pierre arrive au marché. Il avait rendez-vous avec Frank son ami d’enfance. Il l’attendait à un stand de vin chaud. <p/> 
     Les deux amis se saluèrent chaleureusement et commencèrent à discuter :
    “alors comment la savonnerie ? “ demanda Pierre, curieux.
    <p/>
    “Très bien ! On à pas mal de commandes en ligne en ce moment”, répondit Frank
    <p/>
    “Vous avez un site internet, vous ?”
    <p/>
    “Oui, c’est moi qui l’ai fait. Rien de compliqué, avec un outil gratuit en ligne.”...</p>

const text_chapter3 = 
    <p> Le lendemain matin, Pierre repensa à sa conversation avec Frank. <br/> ”Créer un site soi-même… le résultat risque de manquer de performance et de professionnalisme.” 
    <p/>
    Il fronça les sourcils, hésitant. “Est-ce que ça vaut vraiment le coup de le faire seul ?”
    <p/>
    C’est alors qu’un souvenir lui revint ...
    </p>

    return(
        <div className="blog_contain">
            <div className="blog_header">
                <div className="title">
                <h1> WebInBlocks </h1>               
                <h5> Le blog </h5>
                </div>
                <p className="paragraph"> Chaque mois, découvrez une nouvelle partie des aventures de Pierre : un entrepreneur passionné du Valais, en quête de succès malgré les obstacles. Suivez son parcours, ses défis et ses victoires ! </p>
       
            </div>

            <img src={border} alt="color website" className="border_blog"></img>
           <h4 className="post_style"> Derniers chapitres en ligne </h4>
           <div>
           <PostStructure 
           img={chapter3} img_title={"L’appel à un professionnel"}
           title="05.03.2025 - Chapitre 3 : L’appel à un professionnel"
           text={text_chapter3}
           routerOfChapter="/blog/conception-professionnel"
           />
           <PostStructure 
           img={chapter2} img_title={"La première tentative"}
           title="22.01.2025 - Chapitre 2 : La première tentative" 
           text={text_chapter2}
           routerOfChapter="/blog/limites-outils-gratuits"
           />
           <PostStructure 
           img={chapter1} img_title={"manque de visibilité"}
           title="27.11.2024 - Chapitre 1 : le manque de visibilité" 
           text={text_chapter1}
           routerOfChapter="/blog/site-web-pme"
           />
           
           </div>

        </div>
    )
}